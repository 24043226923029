<template>
  <!-- 个人中心 -->
  <div class="personal">
    <nav>
      <ul>
        <li>
          <router-link exact active-class="active" :class="{active: $route.meta.index == 0}" to="/personal">收藏夹
          </router-link>
        </li>
        <li>
          <router-link active-class="active" to="/personal/order">订单中心</router-link>
        </li>
        <li>
          <router-link active-class="active" to="/personal/record">下载记录</router-link>
        </li>
        <li>
          <router-link active-class="active" to="/personal/info">个人信息</router-link>
        </li>
        <li>
          <router-link active-class="active" to="/personal/invoice">申请发票</router-link>
        </li>
      </ul>
    </nav>
    <router-view class="content"></router-view>
  </div>
</template>
<script>

export default {
  name: "Personal",
  data () {
    return {
    }
  },
  components: {},
  created () {
  }
};
</script>
<style scoped lang="scss">
.personal {
  @include flex('', space-between);
  padding-top: 56px;

  nav {
    width: 252px;
    flex-shrink: 0;
    background: #F6F7F9;
    height: calc(100vh - 56px);

    ul {
      padding-top: 24px;

      li {
        a {
          margin-bottom: 16px;
          height: 50px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          float: left;
          line-height: 50px;
          text-align: center;
          @include wh(100%, 100%);

          &.active {
            background: #FFFFFF;
            font-family: PingFangSC-Medium, PingFang SC;
            color: $activeColor;
            font-weight: 500;
          }
        }
      }
    }
  }

  .content {
    margin: 0;
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 56px);
  }
}
</style>
